// Hide cart if user is logged out
.header-logged-out {
  .header-shopping-cart {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1134px) {
  // the header is very snug due to all the links;
  // reducing padding to give it more room. B2B-1994
  .header-nav-item.header-nav-item__header {
    padding: 0 10px;
  }
}

input#variant,
input#quantity {
  // on IE 11, an input with readonly attribute still has a cursor visible
  // setting width here on the inputs so that it's not visible B2B-551
  width: 0px;
}
