@import "variables";

.wrapper {
  max-width: 335px;
  height: 531px;

  button {
    margin: 10px 0;
  }
}

.title {
  margin-top: 20px;
  margin-bottom: 10px;

  .zipCode {
    text-decoration: underline;
  }
}

.imageWrapper {
  text-align: center;
}

.bodyText {
  font-size: 16px;
  line-height: 22.4px;
}
